import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import React, { useEffect, useState } from "react";
import './App.css';
import Shop from './components/card/Shop';
import Footer from './components/footer/Footer';
import Buttons from './components/header/Buttons';
import Header from './components/header/Header';
import TopBar from './components/header/TopBar';
import {ShopContextProvider } from './context/shop-context';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import Cart from "./components/cart/cart";
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import ScrollToTop from "./components/footer/ScrollToTop";
import ContactForm from "./components/contactForm/ContactForm";
import 'react-toastify/dist/ReactToastify.css';
import Visitor from "./components/visitor/Visitor"
import { ToastContainer } from 'react-toastify';

function App() {

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const changeLanguage = (lng) => { 
    i18n.changeLanguage(lng)
    document.body.dir = i18n.dir();
  }

var visitCount = localStorage.getItem("page_view");

if (visitCount) {
  visitCount = Number(visitCount) + 1;
  localStorage.setItem("page_view", visitCount);
} else {
  visitCount = 1;
  localStorage.setItem("page_view", 1);
}

const [isLoading, setIsLoading] = useState(true);
useEffect(()=>{
  const t = setTimeout(()=>{
    setIsLoading(false);
  }, 3000);
  return ()=>{
    clearTimeout(t);
  };
}, []);


  return (
  
//     <div className="App"
//     style={{ 
//       backgroundColor: `app` 
//     }}>
<div className="App"
    style={{ 
      backgroundImage: `url(${process.env.PUBLIC_URL + '/ttt.jpg'})` 
    }}>
      <Helmet>
        <title>Digital Menu </title>
        <meta name="description" content="Digital Menu" />
    </Helmet>
    <ToastContainer />
    <ShopContextProvider>
      <TopBar />
      <Header />
      <div className="sticky-top">
      <ScrollMenu>
    <Buttons/>
    </ScrollMenu>
    </div>
    <Router>
  <Routes>
  <Route exact path="/:storeName"  element={<Shop />}  />
  <Route exact path="/cart/:storeName"  element={<Cart />} />
  </Routes>
  </Router> 
   <ScrollToTop />
   <ContactForm />
    <Footer />
    <Visitor />
    </ShopContextProvider>
    </div>
   
  );
}

export default App;
