
import React, {useContext, useEffect, useState}from 'react'
import { ImFire } from "react-icons/im";
import { RiStarHalfFill } from "react-icons/ri";
import { RiStarSFill } from "react-icons/ri";
import { ShopContext } from '../../context/shop-context';
import { useTranslation } from 'react-i18next';
const Product = (props) => {

    const {id, name, price, image, description, calories, rate, ar_name, ar_description}= props.data;
    const {addToCart, cartItems, vertical } = useContext (ShopContext);
    const cartItemsAmount = cartItems[id];
    // const localhost_url = "https://wajenmenu.com/"
    const localhost_url = process.env.REACT_APP_IMAGE_URL;
    
    // const localhost_url = "https://wajenmenu.com/public/admin/public/";
    
    const [t, i18n] = useTranslation(); 

  return (
     
        <>
        {vertical ? 
        <>
        {i18n.language == "ar"  ?
        
        <div className="col-lg-4 col-md-6 col-sm-6 " dir='rtl'>
        <div className="card shadow my-3 mx-2">
          <div className="c-item"> 
            <img src={localhost_url + image} className="card-img-top rounded-t" alt="..." /> 
            </div>     
            <div className='card-header'>
            <div className="row">
              <div className="col-6">
              <h5 className="card-text text-end text-muted justify-content-center"> <strong><sub>{price} {t('sr')}</sub></strong></h5>
              </div>
              <div className="col-6">
              <h5 className="card-text text-start text-muted"><strong><sub>{calories}</sub></strong><ImFire color="red" size='22px'  className="mx-2" /></h5>
              </div>
                </div>
            </div>
            
            <div className="card-body">
              <h5 className="card-title text-end"><b>{ar_name}</b></h5>
              {ar_description =="null" ?<></> :
              <p className="card-text text-end">{ar_description}</p>
              }
              </div>
              <div className='card-footer'>
              <div className='row'>
              <div className="col-8 text-end">
                <button className='btn btn-secondary btn fw-bold text-dark' onClick={() => addToCart(id)}>{t('btn-name')} {cartItemsAmount > 0 && <> ({cartItemsAmount})</>}</button>
                </div>  
                <div className="col-4 text-start">
                {rate == 5? 
              <h4 className="card-text  text-muted "><strong className="mx-1 "><sub>{rate}</sub></strong><RiStarSFill color="#FFC107" size='30px'/></h4>
             : 
             <h4 className="card-text  text-muted "><strong className="mx-1 "><sub>{rate}</sub></strong><RiStarHalfFill color="#FFC107" size='30px'/></h4>}
              </div>
             
                </div>
              </div>
            </div>
          </div>
        :
        
            <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="card shadow my-3 mx-2 ">
              <div className="c-item">
                <img src={localhost_url + image} className="card-img-top rounded-t" alt="..." /> 
                </div>     
                <div className='card-header'>
                <div className="row">
                  <div className="col-6">
                  <h5 className="card-text text-start text-muted justify-content-center"> <strong><sub>{price} {t('sr')}</sub></strong></h5>
                  </div>
                  <div className="col-6">
                  <h5 className="card-text text-end text-muted"><ImFire color="red" size='22px'  className="mx-2" /><strong><sub>{calories}</sub></strong></h5>
                  </div>
      
                    </div>
                </div>
                
                <div className="card-body">
                  <h5 className="card-title text-center"><b>{name}</b></h5>
                  {description =="null" ?<></> :
                  <p className="card-text">{description}</p>
                  }
                  </div>
                  <div className='card-footer'>
                  <div className='row'>
                    <div className="col-4 text-start">
                    {rate == 5? 
                  <h4 className="card-text  text-muted "><RiStarSFill color="#FFC107" size='30px'/><strong className="mx-1 "><sub>{rate}</sub></strong></h4>
                 : 
                 <h4 className="card-text  text-muted "><RiStarHalfFill color="#FFC107" size='30px'/><strong className="mx-1 "><sub>{rate}</sub></strong></h4>}
                  </div>
                    <div className="col-8 text-end">
                    <button className='btn btn-secondary shadow text-dark'  onClick={() => addToCart(id)}>{t('btn-name')} {cartItemsAmount > 0 && <> ({cartItemsAmount})</>}</button>
                    </div>
                    </div>
                  </div>
                
                </div>
              </div>
}
</>

:
<>
{i18n.language == "ar" ? 

<div class="col-lg-4 col-md-6 col-sm-6 align-items-start">
<div class="card2 card mb-3" >
  <div class="row g-0 align-items-start">
 
   
    <div class="col-4">
      <img src={localhost_url + image} class="img-fluid  d-flex justify-content-start" alt="..."/>
    </div>
    <div class="col-8">
      <div class="card-body">
        <div className='row'>
        <div className='col-7'>
          <h6 class="card-title text-end"><b>{ar_name}</b></h6>
          </div>
          <div className='col-5'>
          <h6 class="card-title text-start"><strong>{price} {t('sr')}</strong></h6>
          </div>
         
        </div>
       
        {ar_description =="null" ?<></> :
        <p className="card-text text-end">{ar_description}</p>
         }
      </div>
    </div>
    <div className='card-footer'>
                  <div className='row'>
                 
                    <div className="col-8 text-end">
                    <button className='btn btn-dark shadow text-white'  onClick={() => addToCart(id)}>{t('btn-name')} {cartItemsAmount > 0 && <> ({cartItemsAmount})</>}</button>
                    </div>
                    <div className="col-4 text-start">
                    <h5 className="card-text text-start text-muted"><strong><sub>{calories}</sub></strong><ImFire color="red" size='20px'  className="mx-2" /></h5></div>
                    </div>
                  </div>
  </div>
  
</div>    
</div>   
:
 <div class="col-lg-4 col-md-6 col-sm-6 align-items-start">
<div class="card2 card mb-3" >
  <div class="row g-0 align-items-start">
 

    <div class="col-4">
      <img src={localhost_url + image} class="img-fluid  d-flex justify-content-start" alt="..."/>
    </div>
    <div class="col-8">
      <div class="card-body">
        <div className='row'>
        <div className='col-7'>
          <h6 class="card-title text-start"><b>{name}</b></h6>
          </div>
          <div className='col-5'>
          <h6 class="card-title text-end"><strong>{price} {t('sr')}</strong></h6>
          </div>
         
        </div>
       
        {description =="null" ?<></> :
        <p className="card-text">{description}</p>
         }
      </div>
    </div>
    
    <div className='card-footer'>
                  <div className='row'>
                 
                    <div className="col-8 text-start">
                    <button className='btn btn-dark shadow text-white'  onClick={() => addToCart(id)}>{t('btn-name')} {cartItemsAmount > 0 && <> ({cartItemsAmount})</>}</button>
                    </div>
                    <div className="col-4 text-end">
                    <h5 className="card-text text-end text-muted"><ImFire color="red" size='20px'  className="mx-2" /><strong><sub>{calories}</sub></strong></h5></div>
                    </div>
                  </div>
  </div>
  
</div>    
</div>   
}
</>
}
</>
           
  )
}

export default Product
